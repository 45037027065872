$layout-breakpoint: 768px;
$layout-topbar-height: 80px;
$layout-sidebar-width: 260px;

.admin-layout {
    min-height: 100vh;
    background: #f9f9f9;

    .admin-layout__sidebar {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        background: #1e212b;

        @media (min-width: $layout-breakpoint) {
            width: $layout-sidebar-width;
        }
    }

    .admin-layout__toggle {
        position: absolute;
        top: 0;
        left: 0;
        padding: 18px;
        cursor: pointer;
        font-size: 16px;
        color: rgba(255, 255, 255, .5);

        @media (min-width: 768px) {
            display: none;
        }
    }

    .admin-layout__body {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }

    .admin-layout__topbar {
        flex-shrink: 0;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 998;
        width: 100%;
        height: $layout-topbar-height;
    }

    .admin-layout__content {
        flex-grow: 1;
        flex-shrink: 1;
        padding: $layout-topbar-height 30px 0 30px;
    }

    .admin-layout__footer {
        flex-shrink: 0;
        padding: 30px;
        margin-top: 40px;
        border-top: 1px solid rgba(0, 0, 0, .1);
        text-align: right;
    }
}

.admin-layout {
    .admin-layout__sidebar {
        display: none;
    }

    &.admin-layout--open {
        @media (max-width: $layout-breakpoint - 1) {
            height: 100vh;
            overflow: hidden;
        }

        .admin-layout__sidebar {
            display: block;
        }

        .admin-layout__body {
            @media (min-width: $layout-breakpoint) {
                margin-left: $layout-sidebar-width;
            }
        }

        .admin-layout__topbar {
            left: $layout-sidebar-width;
            width: calc(100% - $layout-sidebar-width);
        }
    }
}

.admin-layout__nav {
    > * {
        display: block;
    }

    > a.item:first-child {
        border-bottom: 1px solid rgba(255, 255, 255, .1);
    }

    > .item .input .icon {
        right: 10px !important;
    }

    > .item .input {
        display: block;
        width: 100%;
        padding: 8px 15px;
        margin-bottom: 20px;
    }

    .sylius-admin-menu {
        > .item {
            margin: .7em 0 1.6em 0;
        }

        > .item > .header {
            margin-bottom: 1em;
            padding-left: 1.5em;
            color: rgba(255, 255, 255, .9);
            font-weight: 700;
        }

        > .item > .menu > .item {
            display: block;
            margin-right: 5px;
            padding: .4em 1.3em;
            color: rgba(255, 255, 255, .5);
        }

        > .item > .menu > .item.active {
            color: #fff;
        }

        > .item > .menu > .item:hover {
            color: rgba(255, 255, 255, .9);
        }
    }
}
